import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Client, User} from '@app/modules/shared/models';
import {Router} from '@angular/router';
import {DataTableDirective} from 'angular-datatables';
import {select, Store} from '@ngrx/store';
import {smartDateFormat} from '@app/consts';
import * as DataTables from 'datatables.net';

import {
  ClientsList,
  getClientError, getLoggedInUser, getPaginatedClients, getSuccess,
  getUserError, getUserLoginInfoMail,
  getUsersPaginated,
  getUserSuccess, IAuthenticationState, IClientState,
  IUsersState, LoginUserWithId, ResetAuthState, ResetUserState, UserLoginInfoMail, UsersDelete,
  UsersListPaginated, UsersUpdate
} from '@app/stores';
import {ToastrService} from 'ngx-toastr';
import Swal from 'sweetalert2';
import {Observable} from "rxjs";
import {LoaderService} from "@app/services/loader.service";

@Component({
  selector: 'app-users',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  unsubscriber = new Subject();
  dtTrigger: Subject<any> = new Subject();
  ajaxCallback: any;
  private datatableInstance: DataTables.Api<any>;
  private searchDelay;
  public users: User[] = [];
  planners: Client[] = [];
  deleteAction = false;
  updateAction = false;
  loginWithUser = false;
  selectedClient = 'All';
  isAdmin = false;
  isSendEmail = false;
  currentUser: any;
  loader$: Observable<boolean>;
  clientSearchValue = '';
  clientPageNumber = 0;
  totalClients = 0;


  constructor(
    private userStore: Store<IUsersState>,
    private clientStore: Store<IClientState>,
    private authStore: Store<IAuthenticationState>,
    private router: Router,
    private toastrService: ToastrService,
    private loaderService: LoaderService,
  ) {
    this.userStore.dispatch(ResetUserState({params: {error: '', paginatedUsers: null}}));
    this.authStore.dispatch(ResetAuthState({params: {error: '', success: ''}}));
    this.subscribeStores();
  }

  subscribeStores() {
    this.authStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
          if (user) {
            this.currentUser = user;
            this.isAdmin = this.currentUser.role_user[0].role_id === 1;
          }
        }
      );
    this.userStore.pipe(select(getUserError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error);
        }
      });

    this.userStore.pipe(select(getUserSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.deleteAction || this.updateAction) {
            this.deleteAction = false;
            this.updateAction = false;
            this.loadAllUsers();
          }
        }
      });

    this.userStore.pipe(select(getUserLoginInfoMail))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
          if (success && this.isSendEmail) {
            this.toastrService.success(success, 'Success');
            this.isSendEmail = false;
        }
      });

    this.userStore.pipe(select(getUsersPaginated))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((paginatedUsers: any) => {
          if (paginatedUsers) {
            this.users = paginatedUsers.list;
            if (this.ajaxCallback) {
              this.ajaxCallback({
                recordsTotal: paginatedUsers.paging.total,
                recordsFiltered: paginatedUsers.paging.total,
                data: []
              });
              setTimeout(() => {
                (this.datatableInstance as any).columns.adjust();
              }, 500);
            }
          }
        }
      );
   this.clientStore.pipe(select(getPaginatedClients))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(paginatedClients => {
        if (paginatedClients) {
          this.totalClients = paginatedClients.paging.total;
          this.clientPageNumber = parseInt(paginatedClients.paging.page);
          this.planners = [...this.planners, ...paginatedClients.list];
          // this.toastrService = paginatedClients.trial_registration_url;
        }
      });
    this.clientStore.pipe(select(getClientError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error);
        }
      });
    this.authStore.pipe(select(getSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.loginWithUser) {
            this.loginWithUser = false;
            this.router.navigateByUrl('/projects');
          }
        }
      });
  }

  ngOnInit() {
    this.loader$ = this.loaderService.loader$;
    this.datatableSettings();
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api<any>) => {
        dtInstance.destroy();
        this.dtTrigger.next(undefined);
      });
    } else {
      this.dtTrigger.next(undefined);
    }
    this.loadAllPlanners();
  }

  ngAfterViewInit() {
    this.dtTrigger.next(undefined);
  }

  datatableSettings() {
    this.dtOptions = {
      scrollCollapse: true,
      pagingType: 'full_numbers',
      responsive: false,
      ordering: false,
      processing: true,
      pageLength: 10,
      paging: true,
      serverSide: true,
      scrollX: true,
      searching: true,
      // scrollY: "100vh",
      fixedHeader: {
        header: true,
        footer: true,
        headerOffset: 55 ,
      },
      fixedColumns: {
        right: 1,
        left: 0
      },
      drawCallback: function (setting) {
        const totalPages = this.api().page.info().pages;
        if (totalPages <= 1) {
          document.querySelector('.dataTables_paginate').classList.add('d-none');
        } else {
          document.querySelector('.dataTables_paginate').classList.remove('d-none');
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api<any>) => {
          this.datatableInstance = dtInstance;
          this.ajaxCallback = callback;
          const pageLength = dataTablesParameters.length;
          const pageNumber = (dataTablesParameters.start / pageLength);
          (this.datatableInstance as any).page.len(pageLength);
          const searchBox = $('div.dataTables_filter input');
          searchBox.off('keyup.DT input.DT');
          searchBox.on('keyup', () => {
            const search: any = searchBox.val();
            clearTimeout(this.searchDelay);
            this.searchDelay = setTimeout(() => {
              if (search != null) {
                (this.datatableInstance as any).search(search).draw();
              }
            }, 1000);
          });
          this.loadAllUsers(pageNumber, pageLength, dataTablesParameters.search.value);
        });
      },
      columns: [
        {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null},
        {data: null}, {data: null}, {data: null}, {data: null}, {data: null}
      ]
    };
  }

  usersList(event = null) {
    this.selectedClient = 'All';
    if (event) {
      this.selectedClient = event.value;
    } else {
      this.loadAllPlanners();
    }
    this.loadAllUsers();
  }

  loadAllPlanners(search = '', page = 0, perPage = 10, orderBy= {'column': 'name', 'dir': 'asc'}, fields = ['id', 'name']) {
    this.clientPageNumber = page + 1;
    this.clientStore.dispatch(ClientsList({
      params: {
        options: JSON.stringify({includePackage: true, includePagination: true, orderBy, fields}),
        page: this.clientPageNumber,
        perPage,
        search
      }
    }));
  }

  private loadAllUsers(page = 0, perPage = 10, search = '') {
    this.userStore.dispatch(UsersListPaginated({
      params: {
        options: JSON.stringify({include_client: true, includePagination: true}),
        page: page + 1,
        ...(this.selectedClient !== 'All' ? {client_id: this.selectedClient} : {}),
        perPage,
        search
      }
    }));
  }

  deleteUser(userId: number) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.value) {
        this.deleteAction = true;
        this.userStore.dispatch(UsersDelete({userId}));
      }
    });
  }

  changeStatus(userId: number, status: any) {
    Swal.fire({
      title: `Are you sure you want to ${status === '0' ? 'Activate' : 'Deactivate'} this user?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.value) {
        this.updateAction = true;
        this.userStore.dispatch(UsersUpdate({userId, body: {status: status === '0' ? '1' : '0'}}));
      }
    });
  }

  loginToUser(user: any) {
    this.loginWithUser = true;
    this.authStore.dispatch(LoginUserWithId({userId: user.id}));
  }

  sendLoginInfoMail(id) {
    this.isSendEmail = true;
    this.userStore.dispatch(UserLoginInfoMail({userId: id}));
  }

  getUserUpdateInfo(user, type) {
    if (user.created_at && type === 'create') {
      return `Created at ${smartDateFormat(user?.created_at)} ${user.created_by ? 'by ' + user.created_by?.first_name + ' ' + user.created_by?.last_name : ''}`;
    }
    if (user.updated_at && type === 'update') {
      return `Last updated at ${smartDateFormat(user?.updated_at)} ${user.updated_by ? 'by ' + user.updated_by?.first_name + ' ' + user.updated_by?.last_name : ''}`;
    }
  }

  handleSearchValue(searchTerm) {
    this.loader$ = null;
    this.clientSearchValue = searchTerm.term;
    this.planners = [];
    this.loadAllPlanners(searchTerm.term);
  }

  handleScroll() {
    this.loader$ = null;
    if (this.planners.length < this.totalClients) {
      this.loadAllPlanners(this.clientSearchValue, this.clientPageNumber);
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next(undefined);
    this.unsubscriber.complete();
  }
}
