import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {Client, Project} from '@app/modules/shared/models';
import {ActivatedRoute, Router} from '@angular/router';

import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup
} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {
  ClientGet,
  ClientsList,
  getClient,
  getClientError, getFileUploadProgress,
  getLoggedInUser,
  getPaginatedClients,
  getProjectError,
  getProjects, getSignedUrl, getSignedUrlError,
  getUpdateUser, getUploadToSignedUrl, getUploadToSignedUrlError,
  getUser,
  getUserError,
  getUsersRoles,
  getUserSuccess,
  IAuthenticationState,
  IClientState,
  IFileUploadState,
  IProjectState,
  IUsersState,
  LoginSuccess,
  ProjectListAll,
  ResetAuthState,
  ResetFileState,
  ResetProjectState,
  ResetUserState,
  SignedUrl,
  UploadToSignedUrl,
  UsersAdd,
  UsersGet,
  UsersGetSuccess,
  UsersRoles,
  UsersUpdate
} from '@app/stores';
import {Constants} from '@app/consts';
import {takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {User} from '@app/_models';
import {Observable} from "rxjs";
import {LoaderService} from "@app/services/loader.service";

@Component({
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css'],
  providers: [Constants]
})
export class UserAddComponent implements OnInit, OnDestroy {
  addUserForm: UntypedFormGroup;
  unsubscriber = new Subject();
  clients: Client[] = [];
  userId: number;
  submitted = false;
  roles: any = [];
  isAdmin = false;
  currentUser: any;
  showRoles = false;
  showPasswords: any = {
    password: false,
    confirm_password: false,
  };
  passwordStrength: any = '';
  timer = null;
  public projects: Project[] = [];
  public timezones = [];
  user: any = null;
  isAdminUSer = false;
  selectedClientId: any;
  isManager: boolean = false;
  loader$: Observable<boolean>;
  selectedClient = null;
  clientSearchValue = '';
  clientPageNumber = 0;
  totalClients = 0;
  imageSrc: any;
  imageFile = null;
  imageSignedUrl = null;
  fileUploadProgress = 0;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public constants: Constants,
    private clientStore: Store<IClientState>,
    private userStore: Store<IUsersState>,
    private toastrService: ToastrService,
    private authenticationStore: Store<IAuthenticationState>,
    private projectStore: Store<IProjectState>,
    private loaderService: LoaderService,
    private fileUploadStore: Store<IFileUploadState>,
  ) {
    this.userStore.dispatch(ResetUserState({params: {error: '', success: '', user: null, roles: []}}));
    this.fileUploadStore.dispatch(ResetFileState({ params: { error: '', file: null } }));
    this.subscribeStores();
    this.timezones = constants.timezone;
    this.authenticationStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
        this.currentUser = null;
        if (user) {
          this.currentUser = user;
          if (this.currentUser.role_user[0].role.name === 'Admin' || this.currentUser.role_user[0].role.name === 'Manager') {
            this.showRoles = true;
            this.clientStore.dispatch(UsersRoles());
          }
        }
      });
  }

  subscribeStores() {
    this.authenticationStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
          if (user) {
            this.currentUser = user;
            this.isAdminUSer = this.currentUser.role_user[0].role_id === 1;
            if(this.currentUser.role_user[0].role_id === 7){
              this.isManager = true;
            }
          }
        }
      );
    this.clientStore.pipe(select(getPaginatedClients))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(paginatedClients => {
        if (paginatedClients) {
          this.totalClients = paginatedClients.paging.total;
          this.clientPageNumber = parseInt(paginatedClients.paging.page);
          this.clients = [...this.clients, ...paginatedClients.list];
          const clientIds = this.clients.map(item => item.id);
          if (this.user && this.user.client_id && !clientIds.includes(this.user.client_id)) {
            this.loadClient(this.user.client_id);
          }
        }
      });
    this.clientStore.pipe(select(getClient))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(client => {
        if (client) {
          this.clients = [...this.clients, client]
        }
      });
    this.clientStore.pipe(select(getUsersRoles))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(roles => {
        if (roles && roles.length > 0) {
          this.roles = roles;
         if (this.currentUser.role_user[0].role.name !== 'Admin'){
           this.roles = this.roles.filter(item => item.name === 'Manager' ||  item.name === 'Planner');
         }
          if (this.user && this.isAdminUSer) {
            this.isRoleAdmin(this.user.role_user[0].role_id);
            if (!this.isAdmin) {
              this.addUserForm.get('client_id').setValidators(Validators.required);
              this.addUserForm.get('client_id').updateValueAndValidity();
            } else {
              this.addUserForm.get('client_id').clearValidators();
              this.addUserForm.get('client_id').updateValueAndValidity();
            }
          }
        }
      });
    this.clientStore.pipe(select(getClientError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error);
        }
      });
    this.userStore.pipe(select(getUserSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.submitted) {
            this.submitted = false;
            this.toastrService.success(success, 'Success');
            this.cancelClick();
          }
        }
      });
    this.userStore.pipe(select(getUpdateUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(data => {
        if (data && this.currentUser) {
          if (Number(data.id) === Number(this.currentUser.id)) {
            this.currentUser = Object.assign({},  this.currentUser);
            this.currentUser.timezone = data.timezone;
            if (data.token) {
              this.currentUser.token = data.token;
            }
            localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
            this.authenticationStore.dispatch(LoginSuccess({user: this.currentUser}));
          }
        }
      });
    this.userStore.pipe(select(getUserError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error);
        }
      });
    this.userStore.pipe(select(getUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
        if (user) {
          this.user = Object.assign({}, user);
          this.selectedClientId = this.user.client_id;
          this.addUserForm.patchValue({
            first_name: user.first_name,
            last_name: user.last_name,
            profile_img: user.profile_img,
            // client_id: user.client_id,
            ...(this.isAdminUSer ? {client_id: user.client_id} : {client_id: user.client_id}),
            ...(this.showRoles ? {role: user.role_user[0].role_id} : {}),
            job_title: user.job_title,
            email: user.email,
            website: user.website,
            company: user.company,
            phone: user.phone,
            project_id: (user.project_id || '').toString().split(',').filter(Boolean),
            timezone: user.timezone,
          });

          if (user.client_id) {
            this.projectStore.dispatch(ProjectListAll({params: {client_id: user.client_id}}));
          }

          if (this.showRoles && this.isAdminUSer) {
            this.isRoleAdmin(user.role_user[0].role_id);
            if (!this.isAdmin) {
              this.addUserForm.get('client_id').setValidators(Validators.required);
              this.addUserForm.get('client_id').updateValueAndValidity();
            } else {
              this.addUserForm.get('client_id').clearValidators();
              this.addUserForm.get('client_id').updateValueAndValidity();
            }
          }
          this.imageSrc = user.profile_img || '';
        }
      });
    this.projectStore.pipe(select(getProjectError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error);
        }
      });
    this.projectStore.pipe(select(getProjects))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(projects => {
          if (projects && projects.length > 0) {
            this.projects = this.selectedClientId ? projects.filter(item => item.client_id == this.selectedClientId) : projects;

            if (this.user && this.user.project_id) {
              this.addUserForm.patchValue({project_id: []});
              setTimeout(() => {
                this.submitted = false;
                this.addUserForm.patchValue({project_id: (this.user.project_id || '').toString().split(',').filter(Boolean)});
              }, 300);
            }
          }
        }
      );
    this.fileStoreSubscribe();
  }

  activeFileInput(fileInput) {
    fileInput.click();
  }

  fileStoreSubscribe() {
    this.fileUploadStore.pipe(select(getSignedUrlError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error, 'Error');
        }
      });
    this.fileUploadStore.pipe(select(getUploadToSignedUrlError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error, 'Error');
        }
      });
    this.fileUploadStore.pipe(select(getFileUploadProgress))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data !== undefined && data !== null) {
          this.fileUploadProgress = data;
        }
      });
    this.fileUploadStore.pipe(select(getSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && data.url) {
          this.imageSignedUrl = data.url;
        }
      });
    this.fileUploadStore.pipe(select(getUploadToSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && this.imageSignedUrl) {
          const profile_img = this.imageSignedUrl.split('?')[0];
          this.addUserForm.patchValue({profile_img});
          this.imageSignedUrl = null;
          this.imageFile = null;
          this.addUser();
        }
      });
  }

  ngOnInit() {
    this.loader$ = this.loaderService.loader$;
    this.addUserForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      profile_img: [''],
      ...(this.isAdminUSer ? {client_id: ['', Validators.required]}: {client_id: this.currentUser.client_id}),
      //   client_id: ['', Validators.required],
      ...(this.showRoles ? {role: ['', Validators.required]} : {}),
      job_title: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      website: ['', Validators.pattern(this.constants.urlRegex)],
      company: [''],
      phone: [''],
      project_id: [],
      password: [''],
      confirm_password: [''],
      timezone: [null, [Validators.required]],
    },
      {validators: [this.passwordMatchValidator, this.setNotMatchValidation.bind(this)]}
    );
    this.projectStore.dispatch(ResetProjectState({params: {error: '', projects: []}}));
    this.route.paramMap.subscribe(params => {
      if (params.has('id')) {
        this.userId = +params.get('id');
        this.loadUser(this.userId);
      } else {
        // this.addUserForm.get('password').setValidators(Validators.required);
        // this.addUserForm.get('confirm_password').setValidators(Validators.required);
        // this.addUserForm.get('password').updateValueAndValidity();
        // this.addUserForm.get('confirm_password').updateValueAndValidity();
      }
    });
    this.loadAllPlanners();
  }

  setValidation() {
    if (this.userId) {
      if (this.f.password.value) {
        this.addUserForm.get('confirm_password').setValidators(Validators.required);
        this.addUserForm.get('confirm_password').updateValueAndValidity();
      } else {
        this.addUserForm.get('confirm_password').clearValidators();
        this.addUserForm.get('confirm_password').updateValueAndValidity();
      }
    }
  }

  setNotMatchValidation(frm: UntypedFormGroup) {
    if (!frm.controls['password'].value) {
      return;
    }
    if (frm.controls['password'].errors && !frm.controls['password'].errors.passwordRegex) {
      return;
    }
    if (this.passwordStrength >= 100) {
      frm.controls['password'].setErrors(null);
    } else {
      frm.controls['password'].setErrors({passwordRegex: true});
    }
  }

  updatePasswordStrength(event) {
    this.passwordStrength = event;
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.addUserForm.get('password').updateValueAndValidity();
    }, 200);
  }

  passwordMatchValidator(frm: UntypedFormGroup) {
    if (frm.controls['confirm_password'].errors && !frm.controls['confirm_password'].errors.confirmPasswordValidator) {
      return;
    }
    if (frm.controls['password'].value !== frm.controls['confirm_password'].value) {
      frm.controls['confirm_password'].setErrors({confirmPasswordValidator: true});
    } else {
      frm.controls['confirm_password'].setErrors(null);
    }
  }

  get f() {
    return this.addUserForm.controls;
  }

  public addUser() {
    this.submitted = true;
    if (this.addUserForm.invalid) {
      return;
    }
    if (this.imageSignedUrl && this.imageFile) {
      this.fileUploadStore.dispatch(UploadToSignedUrl({files: [{url: this.imageSignedUrl, fileData: this.imageFile}]}));
    } else {
      this.saveUserData();
    }
  }

  saveUserData() {
    const saveParams = {...this.addUserForm.value};
    if (this.addUserForm.get('timezone').value) {
      saveParams.timezone = this.addUserForm.get('timezone').value.nameValue;
    }
    // if (!this.isAdminUSer && !this.userId){
    //   saveParams.client_id = this.currentUser?.client_id;
    // }
    if (!saveParams) {
      delete saveParams.password;
      delete saveParams.confirm_password;
    }
    if (!saveParams.password) {
      delete saveParams.password;
      delete saveParams.confirm_password;
    }

    if (saveParams.project_id) {
      saveParams.project_id = saveParams.project_id.join(',');
    } else {
      delete saveParams.project_id;
    }

    if (this.userId) {
      if (!this.isAdmin) {
        const user = {...this.currentUser};
        user.client_id = this.addUserForm.value.client_id;
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.authenticationStore.dispatch(ResetAuthState({params: {user}}));
      }
      if(this.currentUser.id === this.userId) {
        this.currentUser = { ...this.currentUser, ...this.addUserForm.value}
        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
      }
      this.userStore.dispatch(UsersUpdate({userId: this.userId, body: saveParams}));
    } else {
      this.userStore.dispatch(UsersAdd({body: saveParams}));
    }
  }

  clientSelected(event: any) {
    if (event) {
      this.addUserForm.patchValue({client_id: event.value});
      this.selectedClientId = event.value;
      this.addUserForm.patchValue({project_id: []});
      if (this.user && this.projects.length) {
        this.user.project_id = null;
      }
      this.projectStore.dispatch(ProjectListAll({params: {client_id: event.value}}));
    } else {
      this.loadAllPlanners();
      this.addUserForm.patchValue({client_id: ''});
      this.addUserForm.patchValue({project_id: []});
    }
  }

  isRoleAdmin(roleId) {
    this.isAdmin = false;
    if (roleId) {
      const role = this.roles.find(item => item.id === roleId);
      this.isAdmin = role && role.name === 'Admin';
    }
  }

  projectSelect(event: any) {
    this.addUserForm.patchValue({project_id: event.value});
  }

  timezoneSelect(event: any) {
    this.addUserForm.patchValue({timezone: event.value});
  }

  roleSelected(event: any) {
    if (event) {
      this.addUserForm.patchValue({role: event.value});
      const role = this.roles.find(item => item.id === event.value);
      if (this.isAdminUSer) {
        if (role && role.name === 'Admin') {
          this.addUserForm.patchValue({client_id: null});
          this.addUserForm.get('client_id').clearValidators();
          this.addUserForm.get('client_id').updateValueAndValidity();
        } else {
          this.addUserForm.patchValue({client_id: this.selectedClientId});
          this.addUserForm.get('client_id').setValidators(Validators.required);
          this.addUserForm.get('client_id').updateValueAndValidity();
        }
      }
    }
    this.isRoleAdmin(event ? event.value : null);
  }

  loadUser(userId: number) {
    this.userStore.dispatch(UsersGet({userId}));
  }

  cancelClick() {
    if (!this.showRoles) {
      this.router.navigate(['/projects']);
    } else {
      this.router.navigate(['/users']);
    }
  }

  loadClient(clientId: number) {
    this.clientStore.dispatch(ClientGet({clientId}));
  }

  loadAllPlanners(search = '', page = 0, perPage = 10, orderBy= {'column': 'name', 'dir': 'asc'}, fields = ['id', 'name']) {
    this.clientPageNumber = page + 1;
    this.clientStore.dispatch(ClientsList({
      params: {
        options: JSON.stringify({includePackage: true, includePagination: true, orderBy, fields}),
        page: this.clientPageNumber,
        perPage,
        search
      }
    }));
  }

  handleSearchValue(searchTerm) {
    this.loader$ = null;
    this.clientSearchValue = searchTerm.term;
    this.clients = [];
    this.loadAllPlanners(searchTerm.term);
  }

  handleScroll() {
    this.loader$ = null;
    if (this.clients.length < this.totalClients) {
      this.loadAllPlanners(this.clientSearchValue, this.clientPageNumber);
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next(undefined);
    this.unsubscriber.complete();
  }

  onFileChanged(event) {
    if (event.target.files && event.target.files.length) {
      this.imageFile = event.target.files[0];
      this.fileUploadStore.dispatch(SignedUrl({fileName: this.imageFile.name, contentType: this.imageFile.type}));
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(this.imageFile);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }).then(data => {
        this.imageSrc = data;
      });
    }
  }

  getTimezoneValue(event) {
    this.addUserForm.patchValue({timezone: event});
  }
}
