import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserRoutingModule} from './user-routing.module';
import {ReactiveFormsModule} from '@angular/forms';
import {DataTablesModule} from 'angular-datatables';
import { MatSelectModule } from '@angular/material/select';
import {UserListComponent} from './user-list';
import {UserAddComponent} from './user-add';
import {ProfileComponent} from './profile';
import {SharedModule} from '@app/modules/shared/shared.module';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import { MatMenuModule } from '@angular/material/menu';
import { PopoverModule } from 'ngx-bootstrap/popover';
import {PersonalAccessTokenComponent} from "@app/modules/user/personal-access-token/personal-access-token.component";

@NgModule({
  declarations: [UserAddComponent, UserListComponent, ProfileComponent, PersonalAccessTokenComponent],
  imports: [CommonModule, ReactiveFormsModule, UserRoutingModule, DataTablesModule, MatMenuModule,
    MatSelectModule, SharedModule, NgbPopoverModule,PopoverModule.forRoot()]
})
export class UserModule {
}
